import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { BODY_TEXT_TYPE, HEADER_LEVEL } from "../../utils/commonConst";
import HeaderText from "../common/HeaderText";
import PricingCardV2 from "../common/PricingCardV2";
import { CorePricingTierData } from "../../data/homeData";
import { Breakpoints, Colors, FontWeights } from "../../styles/styleConstants";
import Image from "../common/Image";
import BodyText from "../common/BodyText";
import { isNonEmpty } from "../../utils/commonUtil";

export default function CoreProductPricing(props) {
  const {
    pricingData = CorePricingTierData,
    signupCode,
    title = "Built for people who aren’t millionaires (yet 😉)",
    subTitle,
    backgroundColor = Colors.white,
    fontColor = Colors.almostBlack,
    securityShieldImage = "v2.0/shield.png",
  } = props ?? {};
  return (
    <CoreProductPricingContainer
      style={{
        background: `${backgroundColor}`,
      }}
    >
      <Container
        id="pricing"
        style={{
          background: `${backgroundColor}`,
        }}
      >
        <ProductPricingWrapper>
          <HeaderWrapper>
            <HeaderText
              level={HEADER_LEVEL.LEVEL_1}
              style={{ margin: "0px 12px 12px 0px", color: fontColor }}
            >
              {title}
            </HeaderText>
            {isNonEmpty(subTitle) && (
              <HeaderText
                level={HEADER_LEVEL.LEVEL_4_1}
                style={{
                  margin: "0px 12px 12px 0px",
                  color: fontColor,
                }}
              >
                <span style={{ fontWeight: FontWeights.bolder }}>
                  {subTitle}
                </span>
              </HeaderText>
            )}
          </HeaderWrapper>
          <PricingCardWrapper>
            {pricingData.map((corePricingTier, indx) => {
              return (
                <PricingCardV2
                  signupCode={signupCode}
                  key={`${indx}-ptier-${new Date().getSeconds()}`}
                  pricingTierData={corePricingTier}
                  className="core-pricing-card"
                />
              );
            })}
          </PricingCardWrapper>
          {/* <ContactUsWrapper>
            <EmployerGetInTouchLink />
          </ContactUsWrapper> */}
          <MoneyGuaranteeWrapper>
            <MoneyGuaranteeIconWrapper>
              <Image
                src={securityShieldImage}
                style={{ width: "48px", height: "48px" }}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </MoneyGuaranteeIconWrapper>
            <MoneyGuaranteeTextWrapper>
              <HeaderText
                level={HEADER_LEVEL.LEVEL_5}
                style={{ color: fontColor }}
              >
                Uprise money back guarantee.
              </HeaderText>
              <MoneyGuaranteeSecondaryText
                type={BODY_TEXT_TYPE.BODY_TEXT_SMALL}
              >
                <span style={{ fontWeight: 400, color: fontColor }}>
                  Try us risk-free! If you’re not happy with your purchase,
                  we’ll refund your money.
                </span>
                {/* <UpriseLink linkColor={Colors.almostBlack}>
                  <span style={{ fontWeight: 600 }}>Learn more</span>
                </UpriseLink> */}
              </MoneyGuaranteeSecondaryText>
            </MoneyGuaranteeTextWrapper>
          </MoneyGuaranteeWrapper>
        </ProductPricingWrapper>
      </Container>
    </CoreProductPricingContainer>
  );
}

export const CoreProductPricingContainer = styled.div``;

const ProductPricingWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 24px 0px 48px;
  display: block;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 24px;
`;

const PricingCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;
  gap: 24px;
  @media only screen and (max-width: ${Breakpoints.xl}) {
    gap: 12px;
    padding: 0px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
  }

  .core-pricing-card {
    position: relative;
    min-width: 350px;
    max-width: 350px;
    height: 620px;
    @media (max-width: ${Breakpoints.md}) {
      min-width: 300px;
      max-width: 300px;
    }
  }
`;

const MoneyGuaranteeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  @media (max-width: ${Breakpoints.sm}) {
    align-items: flex-start;
  }
`;
const MoneyGuaranteeIconWrapper = styled.div``;
const MoneyGuaranteeTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const MoneyGuaranteeSecondaryText = styled(BodyText)``;
