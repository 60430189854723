import React from "react";
import styled from "styled-components";
import {
  Breakpoints,
  Spacing,
  Colors,
  FontSizes,
  FontWeights,
} from "../../styles/styleConstants";
import { HEADER_LEVEL } from "../../utils/commonConst";
import Image from "./Image";
import HeaderText from "./HeaderText";
import BodyText from "./BodyText";
import {
  getAppUrlByModule,
  isNonEmpty,
  navigateTo,
} from "../../utils/commonUtil";
import WaitlistModalCta from "./WaitlistModalCta";
import CtaButton from "./CtaButton";
import HelperText from "./HelperText";
import UpriseLink from "./UpriseLink";

export default function PricingCardV2(props) {
  const { pricingTierData, style, signupCode, className } = props ?? {};
  const {
    name,
    price,
    priceTerm,
    plan,
    desc,
    yearly,
    icon,
    actionButton,
    isDisabled,
    isPopular = false,
    availability,
    planHeader,
    learnMoreLink,
  } = pricingTierData ?? {};
  const { path: iconPath, width: iconWidth, height: iconHeight } = icon ?? {};
  const { label, targetLink, width } = actionButton ?? {};
  return (
    <PricingCardWrapper style={style} className={className}>
      {isPopular === true && (
        <MostPopular>
          <HeaderText level={HEADER_LEVEL.LEVEL_7}>MOST POPULAR</HeaderText>
        </MostPopular>
      )}
      <PricingCardBody>
        {/* <div>{tag}</div> */}
        <PricingIconWrapper>
          <Image
            src={iconPath}
            style={{ width: `${iconWidth}`, height: `${iconHeight}` }}
            imgStyle={{
              objectFit: "contain",
            }}
          />
        </PricingIconWrapper>
        <HeaderText
          level={HEADER_LEVEL.LEVEL_5}
          style={{
            color: isDisabled ? Colors.neutralMedDark : Colors.almostBlack,
          }}
        >
          {name}
        </HeaderText>
        {isNonEmpty(price) && (
          <PriceWrapper>
            <HeaderText
              level={HEADER_LEVEL.LEVEL_1}
              style={{
                color: isDisabled ? Colors.neutralMedDark : Colors.almostBlack,
              }}
            >
              {price}
            </HeaderText>
            <HeaderText
              level={HEADER_LEVEL.LEVEL_6}
              style={{
                margin: "0px 0px 4px 4px",
                color: isDisabled ? Colors.neutralMedDark : Colors.almostBlack,
              }}
            >
              {priceTerm}
            </HeaderText>
          </PriceWrapper>
        )}
        {isNonEmpty(planHeader) && (
          <HeaderText level={HEADER_LEVEL.BODY_COPY}>{planHeader}</HeaderText>
        )}

        {plan?.map((ele) => (
          <>
            <div className="d-flex align-items-center">
              <Image
                src={"common/tick_black.png"}
                style={{ width: "24px", height: "24px" }}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
              <HeaderText
                level={HEADER_LEVEL.BODY_COPY}
                style={{ margin: "0px 5px 0px 16px", lineHeight: ".7" }}
              >
                {ele.title}
              </HeaderText>
            </div>
            {ele?.planSub?.length > 0 && (
              <ul style={{ margin: 0 }}>
                {ele?.planSub?.map((ele) => (
                  <li style={{ fontSize: FontSizes.sm }}>{ele}</li>
                ))}
              </ul>
            )}
          </>
        ))}
        {isNonEmpty(desc) && (
          <TextWrapper>
            <HeaderText
              level={HEADER_LEVEL.BODY_COPY}
              style={{
                color: `${
                  isDisabled ? Colors.neutralMedDark : Colors.almostBlack
                }`,
              }}
            >
              {desc}
            </HeaderText>
          </TextWrapper>
        )}
        {isNonEmpty(yearly) && (
          <HeaderText
            level={HEADER_LEVEL.BODY_COPY}
            style={{ marginTop: "2px", fontWeight: FontWeights.bolder }}
          >
            <span style={{ fontWeight: FontWeights.bolder }}>{yearly}</span>
          </HeaderText>
        )}
        {isNonEmpty(availability) && (
          <HeaderText
            level={HEADER_LEVEL.BODY_COPY}
            style={{ marginTop: "2px", fontWeight: FontWeights.bolder }}
          >
            {availability}
          </HeaderText>
        )}
      </PricingCardBody>
      {isNonEmpty(actionButton) && (
        <PricingCardFooter>
          <CtaWrapper>
            {/* <WaitlistModalCta
              signupCode={signupCode}
              ctaText={label}
              actionTargetLink={targetLink}
              style={{ width: width }}
            /> */}
            <CtaButton
              onClick={(evt) => {
                // _onSignup();
                navigateTo(targetLink);
                evt.preventDefault();
                evt.stopPropagation();
              }}
              ctaType="primary"
              name={label}
              isStretchToFullWidth={true}
            />
          </CtaWrapper>
          {isNonEmpty(learnMoreLink) && (
            <LearnMoreLinkWrapper>
              <UpriseLink style={{ margin: 0 }} href={learnMoreLink}>
                Learn more
              </UpriseLink>
            </LearnMoreLinkWrapper>
          )}
        </PricingCardFooter>
      )}
    </PricingCardWrapper>
  );
}

const PricingCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 26px;
  width: 448px;
  height: 480px;
  margin: 12px 0px;

  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: ${Colors.almostBlack};

  @media (max-width: ${Breakpoints.sm}) {
    padding: 12px;
  }
`;

const PricingIconWrapper = styled.div``;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
`;

const PriceDesc = styled(BodyText)``;

const TextWrapper = styled.div`
  flex-basis: 85%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const PricingCardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  flex-basis: 80%;
`;

const CtaWrapper = styled.div`
  margin-top: ${Spacing.xxl};
`;

const PricingCardFooter = styled.div`
  width: 100%;
  flex-basis: 20%;
`;

const MostPopular = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ffdb3c;
  padding: 5px 10px;
`;

const LearnMoreLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;
