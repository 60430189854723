import React from "react";
import Layout from "../../layouts/layout";
import Header from "../../common/header";
import Footer from "../../common/footer";
import styled from "styled-components";
import {
  PressReleaseData,
  WhatYouGetContent,
  FeedbackDataV2,
  VideoCardContent,
  PullPricingTierData,
  TopicsWeCoverFAQData,
  CoreProductTopics,
} from "../../../data/homeData";
import { Colors, Spacing } from "../../../styles/styleConstants";
import CurveTopBg from "../../../images/home/gray-curve-top-bg.png";
import { QUERY_PARAMS } from "../../../utils/commonConst";
import { initCap, isNonEmpty, parseReqParams } from "../../../utils/commonUtil";
import LogoBar from "../../common/logoBar";
import WhatYouGet from "../../all-access/WhatYouGet";
import CustomerFeedback from "../../feedback/customerFeedback";
import VideoCard from "../../common/VideoCard";
import PullHero from "./PullHero";
import TopicsWeCoverFAQ from "../../home/TopicsWeCoverFAQ";
import PullPricing from "./PullPricing";

export default function PullContainer(props) {
  const signupCodeReqParamValue = parseReqParams(QUERY_PARAMS.SIGNUP_CODE);
  const signupCode = `${
    isNonEmpty(signupCodeReqParamValue) ? ` - ${signupCodeReqParamValue}` : ""
  }`;
  return (
    <Layout includeHeader={false} includeFooter={false}>
      <HeroWrapper>
        <Header />
        <PullHero signupCode={signupCode} />
      </HeroWrapper>
      <PressReleaseSectionWrapper>
        <LogoBar content={PressReleaseData} signupCode={signupCode} />
      </PressReleaseSectionWrapper>
      <WhatYouGetWrapper>
        <WhatYouGet whatYouGetData={WhatYouGetContent} />
      </WhatYouGetWrapper>
      <CustomerFeedbackWrapper>
        <CustomerFeedback feedbackData={FeedbackDataV2} />
      </CustomerFeedbackWrapper>
      <VideoCard videoCardData={VideoCardContent} />
      <PullPricing />
      <TopicsWeCoverFAQ
        topicsWeCoverFAQData={CoreProductTopics}
        hideAllAccess={true}
      />
      {/* <StillQuestions /> */}
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Layout>
  );
}

const FooterWrapper = styled.div`
  background-color: white;
  padding-top: ${Spacing.xl};
  background: white url(${CurveTopBg}) top center no-repeat;
  background-size: 100%;
`;

const HeroWrapper = styled.div`
  .HeroImage {
    width: 100%;
  }
`;

const CustomerFeedbackWrapper = styled.div`
  padding-top: ${Spacing.xxxxxl};
`;

const PressReleaseSectionWrapper = styled.div``;

const WhatYouGetWrapper = styled.div``;
