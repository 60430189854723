import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image from "../../common/Image";
import styled from "styled-components";
import { Breakpoints, Colors, Spacing } from "../../../styles/styleConstants";
import { HEADER_LEVEL } from "../../../utils/commonConst";
import HeaderText from "../../common/HeaderText";
import CtaButton from "../../common/CtaButton";
import { getAppUrlByModule, navigateTo } from "../../../utils/commonUtil";

export default function PullHero(props) {
  const { signupCode } = props ?? {};
  const [paramString, setParamString] = useState("");
  useEffect(() => {
    setParamString(window.location.search?.replaceAll("/", ""));
  }, []);

  return (
    <HeroWrapper>
      <Container>
        <Row>
          <Col md={6} lg={5}>
            <UprisePullLogoWrapper>
              <Image
                src="affiliate/uprise-pull-logo.png"
                className="UprisePullImage"
                alt="Uprise + Pull"
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </UprisePullLogoWrapper>
            <HeaderText level={HEADER_LEVEL.LEVEL_1}>
              Never worry about money again.
            </HeaderText>
            <div className="my-4 textBig">
              Add an average $1.5m in net worth back in your pocket with expert
              advice from our team of Certified Financial Planners.
            </div>
            {/* <ValuePropTextWrapper>
              <ValuePropIcon src={SuccessCheck} />
              <ValuePropText>Fully personalized</ValuePropText>
            </ValuePropTextWrapper>
            <ValuePropTextWrapper>
              <ValuePropIcon src={SuccessCheck} />
              <ValuePropText>Reviewed by a (human) expert</ValuePropText>
            </ValuePropTextWrapper>
            <ValuePropTextWrapper>
              <ValuePropIcon src={SuccessCheck} />
              <ValuePropText>100% free</ValuePropText>
            </ValuePropTextWrapper> */}
            <CtaWrapper>
              {/* <WaitlistModalCta signupCode={signupCode} /> */}
              <CtaButton
                onClick={(evt) => {
                  // _onSignup();
                  navigateTo(getAppUrlByModule());
                  evt.preventDefault();
                  evt.stopPropagation();
                }}
                ctaType="primary"
              >
                Get started
              </CtaButton>
            </CtaWrapper>
          </Col>
          <Col md={6} lg={7}>
            <Image className="HeroImage" src="home/illustration_1.png" />
          </Col>
        </Row>
      </Container>
    </HeroWrapper>
  );
}

const HeroWrapper = styled.div`
  margin-top: 86px;
  .HeroImage {
    width: 100%;
    margin-top: ${Spacing.xxl};
  }
  @media (max-width: ${Breakpoints.sm}) {
    margin-top: 40px;
  }
`;

const UprisePullLogoWrapper = styled.div`
  margin: 0px 0px 32px 0px;
  .UprisePullImage {
    width: 223px;
    height: 64px;
    @media (max-width: ${Breakpoints.sm}) {
      height: 60px;
    }
  }
`;

const CtaWrapper = styled.div`
  margin-top: 24px;
`;
