import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { PullPricingTierData } from "../../../data/homeData";
import { Breakpoints, Colors, Spacing } from "../../../styles/styleConstants";
import {
  AFFILIATE_DISCOUNT_CODE,
  AFFILIATE_DISCOUNT_PERCENTAGE,
  PARTNERS,
} from "../../../utils/commonConst";
import { initCap } from "../../../utils/commonUtil";
import Image from "../../common/Image";
import CoreProductPricing from "../../home/CoreProductPricing";

export default function PullPricing(props) {
  const { signupCode } = props ?? {};
  const [paramString, setParamString] = useState("");
  useEffect(() => {
    setParamString(window.location.search?.replaceAll("/", ""));
  }, []);

  return (
    <PullPricingWrapper>
      <Container>
        <UprisePullLogoWrapper>
          <Image
            src="affiliate/uprise-pull-logo.png"
            className="UprisePullImage"
            alt="Uprise + Pull"
            imgStyle={{
              objectFit: "contain",
            }}
          />
        </UprisePullLogoWrapper>
      </Container>
      <CoreProductPricing
        pricingData={PullPricingTierData}
        signupCode={signupCode}
        backgroundColor={Colors.almostBlack}
        fontColor={Colors.white}
        securityShieldImage={"v2.0/shield_white.png"}
        title={`Special pricing for ${initCap(PARTNERS.PULL)} users!`}
        subTitle={`${
          AFFILIATE_DISCOUNT_PERCENTAGE[PARTNERS.PULL]
        }% off Uprise Core with code ${AFFILIATE_DISCOUNT_CODE[PARTNERS.PULL]}`}
      />
    </PullPricingWrapper>
  );
}

const PullPricingWrapper = styled.div`
  margin: 96px 0px;
  padding: 80px 0px 0px;
  display: flex;
  flex-direction: column;
  background: ${Colors.almostBlack};
  @media (max-width: ${Breakpoints.sm}) {
    margin: 40px 0px;
  }
`;

const UprisePullLogoWrapper = styled.div`
  .UprisePullImage {
    width: 223px;
    height: 64px;
    @media (max-width: ${Breakpoints.sm}) {
      height: 60px;
    }
  }
`;
